<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-grid">
        <nav class="nav-row">
          <div class="footer-link-col">
            <ul class="footer-nav">
              <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'contact' }"
                  >{{$t('footer.Contact')}}</router-link
                >
              </li>
                <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'referrals' }"
                  >{{$t('Referral Program')}}</router-link
                >
              </li>
              <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'terms' }"
                  >{{$t('footer.Terms & Conditions')}}</router-link
                >
              </li>
              <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'privacy' }"
                  >{{$t('footer.Privacy Policy')}}</router-link
                >
              </li>

              <ul class="social-links">
                <li>
                  <a
                    class="footer-link"
                    href="https://www.facebook.com/invrs.irs"
                    target="_blank"
                    ><img class="social-icon" src="../../assets/facebook.png"
                  /></a>
                </li>
                <li>
                  <a
                    class="footer-link"
                    href="https://www.instagram.com/joininvrs/"
                    target="_blank"
                    ><img class="social-icon" src="../../assets/instagram.png"
                  /></a>
                </li>
                <li>
                  <a
                    class="footer-link"
                    href="https://www.linkedin.com/company/invrs/"
                    target="_blank"
                    ><img class="social-icon" src="../../assets/linkedin.png"
                  /></a>
                </li>
                <li>
                  <a
                    class="footer-link"
                    href="https://twitter.com/JoinINVRS"
                    target="_blank"
                    ><img class="social-icon" src="../../assets/twitter.png"
                  /></a>
                </li>
              </ul>
            </ul>
            <p class="copyright" v-html="$t('footer.copyright')">
              
            </p>
          </div>
        </nav>

        <nav class="nav-row main-footer-links">
          <div class="footer-link-col">
            <ul class="footer-nav">
              <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'home' }"
                  >{{$t('footer.Your Journey')}}</router-link
                >
              </li>
              <!-- <li class="footer-link">
                <router-link class="footer-link" :to="{ name: 'contest' }"
                  >Contest</router-link
                >
              </li> -->
            </ul>
          </div>
        </nav>
        <div class="newsletter-col">
          <router-link class="newsletter-logo" :to="{ name: 'newsletter' }">
            <img
              class="newsletter-logo-img"
              alt="WELL-VRSD"
              src="../../assets/newsletter-logo.png"
            />
          </router-link>

          <p class="newsletter-text">
            {{$t('footer.newsletter')}}
          </p>

          <form @submit.prevent="submitNewsletter" v-if="!emailSubmitted">
            <input
              type="email"
              :placeholder="$t('footer.placeholder')+'@email.com'"
              v-model="userEmail"
            />
            <button>{{$t('footer.Subscribe')}}</button>
          </form>
          <p class="newsletter-text" v-else>You're in! 👊 Look out for a new Well-VRSD email every Monday morning!</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      userEmail: "",
      portalId: "20584317",
      formGuid: "f125bf65-8121-4fa3-a67c-b51b6821bde9",
      emailSubmitted: false,
    };
  },
  methods: {
    async submitNewsletter() {

      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: this.userEmail,
              },
            ],
          }),
        }
      );

      if (response.ok) {
        this.emailSubmitted = true;
      }
    },
  },
};
</script>

<style scoped>
.footer {
  border-top: 1px solid #eee;
  padding: 4.8rem 0;
}

.footer-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 2fr 1.5fr 3fr;
}

.social-links {
  list-style: none;
  display: flex;
}

.newsletter-logo {
  display: block;
  margin-bottom: 1.6rem;
}

.newsletter-logo-img {
  height: 4rem;
}

.newsletter-col {
  display: flex;
  flex-direction: column;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #767676;
  margin-top: 2.4rem;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.footer-link {
  margin-bottom: 2.4rem;
  margin-right: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #767676;
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #555;
}

.newsletter-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #767676;
  margin-bottom: 1.6rem;
}

form {
  width: 100%;
  display: flex;
  align-items: center;
}

input {
  transition: all 0.2s ease-in;
  border-radius: 8px;
  width: 100%;
  margin-right: 1.6rem;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-family: inherit;
  background: #f7f8fc;
  border: 2px solid transparent;
  color: #a5a5a5;
}

input:hover,
input:focus {
  outline: none;
  border: 2px solid #33cc99;
}

::placeholder {
  color: #a5a5a5;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}

button:hover {
  box-shadow: 0 0.4rem 2.4rem rgb(105, 59, 245, 0.6);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .footer-grid {
    grid-template-columns: 1fr;
  }

  .newsletter-col {
    grid-row: 1;
    margin-bottom: 2.4rem;
  }

  .main-footer-links {
    grid-row: 2;
  }

  form {
    width: 80%;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  form {
    width: 100%;
  }

  button {
    padding: 8px 16px;
  }
}
</style>
