<template>
    <section>
      <div class="container">
        <div class="journey-section-data hidden">
            <Carousel :value="slides" :numVisible="1" :numScroll="1" circular :autoplayInterval="8000" :showIndicators="false">
                <template #item="slotProps">
                    <span class="slide-heading">{{ $t('home.'+slotProps.data.header) }}</span>
                    <div class="slide-img-container">
                     
                        <img class="slide-img" src="../../assets/learn-and-earn.png" v-if="slotProps.index === 0"/>
                         <img class="slide-img" src="../../assets/compete.png" v-else-if="slotProps.index === 1"/>
                        <img class="slide-img" src="../../assets/create.png" v-else/>
                    </div>
                    <p class="slide-text" v-html="slotProps.data.text">
                    </p>
                </template>
             </Carousel>
          </div>
      </div>
    </section>
  </template>
  
  <script>
  import Carousel from 'primevue/carousel';

  export default {
    components: {
        Carousel
    },

    data() {
      return {
        
        obsOptionsData: {
          root: null,
          threshold: 0.2,
        },
        section: "",
      };
    },

    computed: {
      slides(){
        return [
           {
            header: 'LEARN & EARN',
            text: this.$t('home.learnEarnText')
        },
        {
            header: 'COMPETE',
            img: '../../src/assets/compete.png',
            text: this.$t('home.competeText')
        },
         
        {
            header: 'COLLABORATE',
            img: '../../src/assets/learn-and-earn.png',
            text: this.$t('home.collabText')
        }
        ]
      }
    },
  
    mounted: function () {
      this.section = document.querySelector(".journey-section-data");
      const dataSectionObserver = new IntersectionObserver(
        this.revealSection,
        this.obsOptionsData
      );
      dataSectionObserver.observe(this.section);
    },
  
    methods: {
    //   goToSlide(slide) {
    //     this.slides.forEach((s, i) => s.style.transform = `translateX(${(i - slide) * 100}%`);
    //   },
  
    //   nextSlide() {
    //     if (this.curSlide === this.slides.length - 1) {
    //       this.curSlide = 0;
    //     } else {
    //       this.curSlide++;
    //     }
  
    //     this.goToSlide(this.curSlide);
    //   },
  
    //   init() {
    //     this.slides = document.querySelectorAll(".journey-slide");

    //     this.goToSlide(this.curSlide);

    //     setInterval(this.nextSlide, 5000);
    //   },
  
      revealSection(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.section.classList.remove("hidden");
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  section {
    width: 100%;
    padding: 2.4rem 0;
    transform: skewY(3deg);
  }
  
  .container {
    transform: skewY(-3deg);
    padding: 0;
  }
  
  ::v-deep(.p-carousel-items-content) {
    width: 60%;
    height: 55rem;
    overflow: hidden;
    margin: 0 auto;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  ::v-deep(.p-carousel-item) {
    text-align: center;
    padding: 2.4rem;

    width: 100%;
    height: 55rem;

    border-radius: 50px;

    background: #fff;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

  }

  .slide-img-container {
    width: 80%;
  }
  .slide-img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    align-self: center;
    border-radius: 8px;
  }
  
  .slide-heading {
    color: black;
    margin-bottom: 0.8rem;
    font-weight: 600;
    font-size: 3.4rem;
  }
  
  .slide-text {
    white-space: pre-wrap;
    color: black;
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .journey-section-data {
    transition: all 0.3s ease-in;
  }
  
  .hidden {
    transform: skewY(-3deg);
    transform: translateY(4.8rem);
    opacity: 0;
  }
  
  /* **************************** */
  /* BELOW 800px (tablet) */
  /* **************************** */
  @media (max-width: 50em) {
    .slide-img-container {
        width: 100%;
    }

    ::v-deep(.p-carousel-items-content) {
      width: 75%;
    }

  }
  
  /* **************************** */
  /* BELOW 592px (mobile) */
  /* **************************** */
  @media (max-width: 37em) {
    .slide-img-container {
        width: 100%;
    }

    ::v-deep(.p-carousel-item) {
      height: 50rem;
      padding: 2.4rem;
    }
  
    ::v-deep(.p-carousel-items-content) {
      width: 100%;
      height: 50rem;
    }
  
    .slide-heading {
      font-size: 2.4rem;
      margin-bottom: 0.6rem;
    }
  }
  
  /* **************************** */
  /* BELOW 496px (mobile) */
  /* **************************** */
  @media (max-width: 31em) {
    .slide-img-container {
        width: 100%;
    }

    ::v-deep(.p-carousel-item) {
      height: 45rem;
      padding: 1.2rem;
      border-radius: 25px;
    }
  
    ::v-deep(.p-carousel-items-content) {
      width: 100%;
      height: 45rem;
      border-radius: 25px;
    }

    .slide-heading {
      font-size: 2.2rem;
      margin-bottom: 0.4rem;
    }
  }
  
  /* **************************** */
  /* BELOW 432px (mobile) */
  /* **************************** */
  @media (max-width: 27em) {
    .slide-img-container {
        width: 100%;
    }
    
    ::v-deep(.p-carousel-item) {
      padding: 1.2rem;
      height: 45rem;
      border-radius: 25px;
    }
  
    ::v-deep(.p-carousel-items-content) {
      height: 45rem;
      border-radius: 25px;
    }
  }
  </style>